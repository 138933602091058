var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { attrs: { flat: "" } }, [
    _c("h1", [
      _vm._v(
        "Allgemeine Geschäftsbedingungen für die Strombelieferung von Sondervertragskunden"
      ),
    ]),
    _c("ol", { staticClass: "pt-4" }, [
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [
          _vm._v(
            "Bereitstellungs- und Lieferpflicht der NEW Niederrhein Energie und Wasser GmbH"
          ),
        ]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "NEW Niederrhein Energie und Wasser GmbH (NEW) stellt dem Kunden elektrische Energie im vereinbarten Umfang an der Übergabestelle für den Lieferzeitraum bereit. NEW kann sich zur Erfüllung ihrer Lieferverpflichtung Erfüllungsgehilfen und Kooperationspartnern bedienen. "
            ),
          ]),
          _c("li", [
            _vm._v(
              "Der Kunde stellt der NEW den zu erwartenden Lastverlauf für die Stromlieferung im ¼-Stundenraster zur Verfügung. Ändern sich Bedarfs- oder Abnahmeverhältnisse gegenüber den vertraglich zugrunde gelegten nachhaltig, wird der Kunde dies der NEW unverzüglich schriftlich mitteilen. "
            ),
          ]),
          _c("li", [
            _vm._v(
              "Soweit und solange NEW an dem Bezug oder der vertragsgemäßen Lieferung von elektrischer Energie durch höhere Gewalt oder sonstige Umstände, deren Beseitigung ihr nicht möglich ist oder wirtschaftlich nicht zugemutet werden kann, gehindert ist, ruht die Verpflichtung zur Lieferung. Dies gilt auch, soweit und solange der Verteilnetzbetreiber den Netzanschluss und die Anschlussnutzung unterbrochen hat, es sei denn, der Kunde hat dies zu vertreten. Bei einer Unterbrechung oder bei Unregelmäßigkeiten in der Stromversorgung ist, soweit es sich um Folgen einer Störung des Netzbetriebs einschließlich des Netzanschlusses handelt, NEW von der Leistungspflicht befreit."
            ),
            _c("br"),
            _vm._v(
              " Der Kunde wird seinerseits in diesem Fall von seinen Leistungspflichten für die Zeit des Ruhens der Verpflichtungen von NEW befreit. Gleiches gilt, wenn der Kunde an der Abnahme der elektrischen Energie durch entsprechende Vorkommnisse in seinem Bereich gehindert ist."
            ),
          ]),
          _c("li", [
            _vm._v(
              "NEW ist zur Lieferung elektrischer Energie nur verpflichtet, wenn der Netzzugang durch den Verteilnetzbetreiber gewährt wurde. Ist diese Voraussetzung nicht gegeben und kommt es somit zur Verzögerung der Stromlieferung, trägt der Kunde die daraus entstehenden Kosten, es sei denn, der Kunde hat dies nicht zu vertreten."
            ),
          ]),
        ]),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Verwendung der elektrischen Energie")]),
        _c("br"),
        _vm._v(
          " Die elektrische Energie wird für den Eigenverbrauch des Kunden und der mit ihm im Sinn der §§ 15 ff AktG verbundenen Unternehmen zur Verfügung gestellt. Der Kunde gewährleistet, dass zum Zeitpunkt der Belieferung durch NEW kein entsprechender Liefervertrag mehr zwischen dem Kunden und einem anderen Lieferanten besteht. "
        ),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Abrechnung und Bezahlung")]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "Die Rechnungen und Abschläge sind zum angegebenen Datum fällig, die Zahlungen erfolgen ohne Abzug. "
            ),
          ]),
          _c("li", [
            _vm._v(
              "Bei verspäteter Zahlung ist NEW berechtigt, Verzugszinsen in der jeweils gesetzlichen Höhe bzw. Mahngebühren zu verlangen."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Gegen Ansprüche der NEW kann der Kunde nur mit unbestrittenen oder rechtskräftig festgestellten Gegenansprüchen aufrechnen."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Einwände gegen Rechnungen berechtigen zum Zahlungsaufschub oder zur Zahlungsverweigerung nur, "
            ),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "soweit die ernsthafte Möglichkeit eines offensichtlichen Fehlers besteht, oder"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "sofern der in einer Rechnung angegebene Verbrauch ohne ersichtlichen Grund mehr als doppelt so hoch wie der vergleichbare Verbrauch im vorherigen Abrechnungszeitraum ist und der Kunde eine Nachprüfung der Messeinrichtung veranlasst hat und solange durch die Nachprüfung nicht die ordnungsgemäße Funktion des Messgeräts festgestellt ist. "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Vorauszahlung")]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "NEW ist berechtigt, für den Stromverbrauch eines Abrechnungszeitraums Vorauszahlungen zu verlangen, wenn Grund zur Annahme besteht, dass der Kunde seinen Zahlungsverpflichtungen nicht oder nicht rechtzeitig nachkommt. "
            ),
          ]),
          _c("li", [
            _vm._v(
              "Die Höhe der Vorauszahlung bemisst sich nach dem Verbrauch des vorhergehenden Abrechnungszeitraums oder dem durchschnittlichen Verbrauch vergleichbarer Kunden."
            ),
          ]),
        ]),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Sicherheitsleistung")]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "NEW ist berechtigt, in begründeten Fällen eine angemessene Sicherheitsleistung vom Kunden zu verlangen."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Kommt der Kunde einem schriftlichen Verlangen nach Sicherheitsleistung nicht binnen zehn Kalendertagen nach, darf NEW die Lieferung ohne weitere Ankündigung durch den Netzbetreiber unterbrechen lassen, bis die Sicherheit geleistet ist."
            ),
          ]),
          _c("li", [
            _vm._v("Als begründeter Fall gilt insbesondere, dass "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "der Kunde mit fälligen Zahlungen trotz Mahnung wiederholt im Verzug ist"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "gegen den Kunden Zwangsvollstreckungsmaßnahmen eingeleitet sind"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "die von NEW über den Kunden eingeholte Auskunft einer allgemein im Geschäftsleben anerkannten Auskunftei (z.B. Creditreform) über seine wirtschaftlichen Verhältnisse die begründete Besorgnis erhärtet, der Kunde werde den Verpflichtungen aus diesem Vertrag nicht nachkommen."
                ),
              ]),
            ]),
          ]),
          _c("li", [
            _vm._v(
              "Sofern der Kunde eine fällige Rechnung trotz Mahnung nicht bezahlt und NEW die Lieferung einstellt, ist NEW berechtigt, die Wiederaufnahme der vertraglich vereinbarten Lieferung von einer Sicherheitsleistung und der Zahlung aller offenen Beträge abhängig zu machen. Geschieht dies nicht, ist NEW berechtigt, den Vertrag fristlos zu kündigen. "
            ),
          ]),
          _c("li", [
            _vm._v(
              "Als angemessen gilt eine Sicherheitsleistung, wenn sie dem dreifachen voraussichtlichen monatlichen Entgelt nach diesem Vertrag entspricht."
            ),
          ]),
          _c("li", [
            _vm._v(
              "NEW kann nach fruchtlosem Verstreichen einer nach Verzugseintritt gesetzten angemessenen Frist die Sicherheitsleistung in Anspruch nehmen."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Soweit NEW eine Sicherheitsleistung verlangt, kann diese auch in Form einer selbstschuldnerischen, unwiderruflichen Bürgschaft einer Großbank mit Sitz in der Bundesrepublik Deutschland mit Verzicht auf die Einrede der Vorausklage und mit der Verpflichtung zur Zahlung auf erstes Anfordern erbracht werden. Barsicherheiten werden zum jeweiligen Basiszinssatz verzinst."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Eine Sicherheit ist unverzüglich zurückzugeben, wenn ihre Voraussetzungen weggefallen sind. Dies ist frühestens dann der Fall, wenn der Kunde sich für einen Zeitraum von mindestens sechs aufeinander folgenden Monaten nicht mit einer Zahlung in Verzug befindet, es sei denn, andere Umstände begründen die Besorgnis, der Kunde werde den Verpflichtungen aus diesem Vertrag nicht nachkommen. "
            ),
          ]),
        ]),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Unterbrechung der Versorgung")]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "NEW ist berechtigt, die Versorgung ohne vorherige Androhung durch den Verteilnetzbetreiber unterbrechen zu lassen, wenn der Kunde diesem Vertrag in nicht unerheblichem Maße schuldhaft zuwiderhandelt und die Unterbrechung erforderlich ist, um den Gebrauch von elektrischer Arbeit unter Umgehung, Beeinflussung oder vor Anbringung der Messeinrichtung zu verhindern."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Bei anderen Zuwiderhandlungen, insbesondere bei der Nichterfüllung einer Zahlungsverpflichtung trotz Mahnung, ist NEW berechtigt, die Versorgung zehn Kalendertage nach Androhung durch den Verteilnetzbetreiber unterbrechen zu lassen. NEW kann mit der Mahnung zugleich die Unterbrechung der Versorgung androhen."
            ),
          ]),
          _c("li", [
            _vm._v(
              "NEW hat die Versorgung unverzüglich wiederherstellen zu lassen, sobald die Gründe für ihre Unterbrechung entfallen sind und der Kunde die Kosten der Unterbrechung und Wiederherstellung der Belieferung ersetzt hat und alle fälligen Beträge beglichen sind. "
            ),
          ]),
        ]),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Kündigung")]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "Der Vertrag kann von beiden Vertragspartnern aus wichtigem Grund ohne Einhaltung einer Frist gekündigt werden. Die Kündigung bedarf der Textform. "
            ),
          ]),
          _c("li", [
            _vm._v(
              "Ein wichtiger Grund liegt für eine Partei insbesondere vor, "
            ),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "wenn die andere Partei länger als vierzehn Tage in Folge oder länger als dreißig Tage innerhalb eines Zeitraums von drei Monaten von ihren vertraglichen Verpflichtungen aufgrund höherer Gewalt befreit war, oder "
                ),
              ]),
              _c("li", [
                _vm._v(
                  "wenn Gründe für die Eröffnung des Insolvenzverfahrens gegen die andere Partei vorliegen oder die andere Partei einen Antrag auf Eröffnung des Insolvenzverfahrens stellt sowie wenn ein Zwangsvollstreckungsverfahren gegen das gesamte Vermögen der anderen Partei oder eines wesentlichen Teils ihres Vermögens eingeleitet wurde. Gleiches gilt, wenn die Eröffnung des Insolvenzverfahrens mangels Masse abgelehnt wurde. "
                ),
              ]),
            ]),
          ]),
          _c("li", [
            _vm._v(
              "Ein wichtiger Grund liegt für den Lieferanten weiterhin insbesondere vor, "
            ),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "wenn der Kunde in nicht unerheblichem Maße schuldhaft elektrische Energie unter Umgehung, Beeinflussung oder vor Anbringung der Messeinrichtungen verwendet,"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "wenn der Kunde mit einer Zahlung aus dem Vertrag in Verzug ist und seiner Zahlungspflicht nicht innerhalb einer Woche nach Zugang der Mahnung mit Kündigungsandrohung nachkommt,"
                ),
              ]),
              _c("li", [
                _vm._v(
                  "wenn der Kunde trotz Mahnung mit Kündigungsandrohung innerhalb der vom Lieferanten gesetzten Frist von einer Woche eine nach dem Vertrag geschuldete Vorauszahlung oder Sicherheit nicht oder nur teilweise leistet. "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Haftung")]),
        _c("br"),
        _vm._v(
          " Die Haftung der NEW für Schäden des Kunden, gleich aus welchem Rechtsgrund, ist ausgeschlossen. Dies gilt nicht, sofern die Pflichtverletzung auf Vorsatz oder grobe Fahrlässigkeit zurückzuführen ist, oder es sich dabei um Verletzung des Lebens, des Körpers, der Gesundheit oder um Verletzungen wesentlicher Vertragspflichten handelt. Der Schadensersatz für die Verletzung wesentlicher Vertragspflichten ist jedoch auf den vertragstypischen, vorhersehbaren Schaden begrenzt. Es wird darauf hingewiesen, dass bei einer Unterbrechung oder Unregelmäßigkeiten in der Stromversorgung, soweit es sich um Folgen einer Störung des Netzbetriebs einschließlich des Netzanschlusses handelt, Ansprüche gegen den Verteilnetzbetreiber geltend gemacht werden können. "
        ),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Übertragung von Rechten und Pflichten")]),
        _c("br"),
        _vm._v(
          " Die Übertragung von Rechten und Pflichten aus diesem Vertrag ist außerhalb der gesetzlichen Gesamtrechtsnachfolge nur mit schriftlicher Zustimmung des anderen Vertragspartners zulässig. Die Zustimmung darf nur bei Vorliegen eines wichtigen Grundes verweigert werden. Für den Fall, dass NEW diesen Vertrag auf eine verbundene Gesellschaft im Sinn der §§ 15 ff AktG überträgt, gilt die Zustimmung des Kunden als erteilt. "
        ),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Änderungsvorbehalt")]),
        _c("br"),
        _vm._v(
          " NEW ist berechtigt, die Allgemeinen Geschäftsbedingungen zu ändern. Eine solche Vertragsanpassung wird dem Kunden mit einer Frist von mindestens 3 Monaten schriftlich angekündigt. In diesem Fall ist der Kunde innerhalb von 6 Wochen nach Erhalt der Änderungsmitteilung berechtigt, dieser Vertragsanpassung schriftlich zu widersprechen. Auf dieses Widerspruchsrecht und dessen Bedeutung sowie auf die Bedeutung eines nicht erfolgten Widerspruchs wird der Kunde im Ankündigungsschreiben gesondert hingewiesen. "
        ),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Geheimhaltung")]),
        _c("br"),
        _vm._v(
          " Die Vertragspartner werden die Strompreise dieses Vertrages vertraulich behandeln. "
        ),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Gerichtsstand")]),
        _c("br"),
        _vm._v(
          " Gerichtsstand ist Mönchengladbach, wenn der Kunde Kaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen ist. "
        ),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Teilunwirksamkeit")]),
        _c("br"),
        _vm._v(
          " Sollten einzelne Bestimmungen dieses Vertrags unwirksam sein oder werden, so wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Beide Vertragspartner verpflichten sich, die unwirksamen Bestimmungen durch im wirtschaftlichen und technischen Erfolg möglichst gleichkommende Bestimmungen zu ersetzen. Entsprechendes gilt, wenn während der Laufzeit des Vertrags eine ausfüllungsbedürftige Regelungslücke entsteht. "
        ),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Bonitätsauskunft")]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "Sofern die NEW in Vorleistung tritt, ist die NEW berechtigt, eine Bonitätsauskunft auf der Basis mathematisch-statistischer Verfahren über den Kunden bei der SCHUFA Holding AG (Massenbergstr. 9 – 13, 44787 Bochum), bei der Creditreform Boniversum GmbH (Hellersbergstr. 11, 41460 Neuss), bei der Coface Rating GmbH (Isaac-Fulda-Allee 1, 55124 Mainz) oder bei der Creditsafe Deutschland GmbH (Schreiberhauer Str. 30, 10317 Berlin) einzuholen. Zu diesem Zweck übermittelt die NEW die zu einer Bonitätsprüfung benötigten personenbezogenen Daten an die SCHUFA, die Creditreform Boniversum, die Coface Rating oder die Creditsafe Deutschland und verwendet die erhaltenen Informationen über die statistische Wahrscheinlichkeit eines Zahlungsausfalls für eine abgewogene Entscheidung über die Begründung, Durchführung oder Beendigung des Vertragsverhältnisses."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Die Bonitätsauskunft kann Wahrscheinlichkeitswerte (Score-Werte) beinhalten, die auf Basis wissenschaftlich anerkannter mathematisch-statistischer Verfahren berechnet werden und in deren Berechnung unter anderem Anschriftendaten einfließen. Die schutzwürdigen Belange des Kunden werden gemäß den gesetzlichen Bestimmungen berücksichtigt."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Der Kunde kann bei der SCHUFA, der Creditreform Boni-versum, der Coface Rating oder der Creditsafe Deutschland Auskunft über die ihn betreffenden Daten verlangen."
            ),
          ]),
        ]),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Datenschutz")]),
        _c("br"),
        _vm._v(
          " Die NEW verarbeitet ihre Daten auf Basis des Artikels 6 Abs. 1 lit. b) und f) der EU-Datenschutzgrundverordnung. Die detaillierten Informationen zum Datenschutz gemäß Art. 13 und Art. 14 DSGVO erhalten Sie mit diesen AGB; im Übrigen jederzeit im Internet unter www.new.de/datenschutz oder sie werden Ihnen auf Ihren Wunsch hin zugesandt. Die Kontaktdaten unseres Datenschutzbeauftragten lauten: Betrieblicher Datenschutzbeauftragter, Odenkirchener Straße 201, 41236 Mönchengladbach, Telefon 02166 688-2220, E-Mail: datenschutzbeauftragter@new.de. "
        ),
      ]),
      _c("li", { staticClass: "pt-4" }, [
        _c("strong", [_vm._v("Schlussbestimmungen")]),
        _c("ol", [
          _c("li", [
            _vm._v(
              "Mündliche Nebenabreden bestehen nicht. Änderungen oder Ergänzungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Textform. Gleiches gilt für die Änderung der Textformklausel."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Die im Vertrag nebst Preisregelung getroffenen Regelungen sowie das Anschreiben haben Vorrang vor diesen Allgemeinen Geschäftsbedingungen."
            ),
          ]),
          _c("li", [
            _vm._v(
              "Im Übrigen gilt entsprechend die Verordnung über Allgemeine Bedingungen für die Grundversorgung von Haushaltskunden und die Ersatzversorgung mit Elektrizität aus dem Niederspannungsnetz (Stromgrundversorgungsverordnung - StromGVV) vom 26.10.2006 (BGBl. I Nr. 50 S. 2391) http://www.gesetze-im-internet.de/stromgvv/ in ihrer jeweils gültigen Fassung, sowie die Ergänzenden Bedingung."
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }